import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {
  Q9IntercomService,
  Q9NotificationsService,
  Q9TaskNotificationsService,
  Q9UserService
} from '@q9elements/ui-core';
import {get} from 'lodash';
import {CookieService} from 'ngx-cookie-service';
import * as Raven from 'raven-js';
import {Observable, throwError} from 'rxjs';
import {catchError, share, tap} from 'rxjs/operators';

import {environment} from '../../../environments/environment';
import {AllowToRedirectService} from '../../account/services/allow-to-redirect.service';
import {TeamInterface} from '../../teams/model/team-interface';
import {TeamUtilService} from '../../teams/services/team-util.service';
import {CloudName, CloudType} from '../../util/cloud-type.enum';
import {FirebaseService} from '../firebase/firebase.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  token: string;
  apiUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private teamUtilService: TeamUtilService,
    private http: HttpClient,
    private q9UserService: Q9UserService,
    private firebaseService: FirebaseService,
    private q9IntercomService: Q9IntercomService,
    private q9NotificationsService: Q9NotificationsService,
    private allowToRedirectService: AllowToRedirectService,
    private q9TaskNotificationsService: Q9TaskNotificationsService
  ) {
    if (this.cookieService.check('auth-token')) {
      this.token = this.cookieService.get('auth-token');
    }
    this.apiUrl = `${environment.API_ENDPOINT}`;
  }

  static getSameSite(): any {
    return environment.SECURE_COOKIES ? 'None' : 'Lax';
  }

  invalidateUserSession(sessionKey: string): Promise<any> {
    return this.http
      .put(`${this.apiUrl}/account/sessions`, {sessionKey}, {responseType: 'text'})
      .toPromise();
  }

  logOut() {
    return this.http.delete(`${this.apiUrl}/auth`, {responseType: 'text'}).pipe(
      tap(() => {
        this._clearSession();
        this.cleanCookie();
        this.destroyIntercom();
        Raven.clearContext();
        localStorage.clear();
        this.teamUtilService.clearContext();
      })
    );
  }

  loginOrRegister({remember = false, ...payload}, method: 'post' | 'put' = 'post') {
    const initializeUser = tap(({token, user}: any) => {
      this._setToken(token, remember);

      if (method === 'post') {
        this._storeSession(user);
      } else {
        this.q9UserService.user = user;
      }
    });

    return this.http[method](`${this.apiUrl}/auth`, payload).pipe(initializeUser, share());
  }

  checkRedirectUrl(queryParams: Params = {}, defaultTeamIndex: any = false): void {
    const {v: version} = queryParams;
    const redirect = get(queryParams, 'redirect', this.getRedirect());

    if (redirect && this.allowToRedirectService.allowed(redirect)) {
      const targetUrl = version ? `${redirect}&v=${version}` : redirect;

      this.deleteRedirect();

      return (window.location.href = targetUrl);
    } else if (typeof defaultTeamIndex === 'number') {
      this.router.navigate(['t', defaultTeamIndex]);
    } else {
      this.router.navigate(['']);
    }
  }

  lookupEmail(payload: {email: string; pw: boolean}): Observable<any> {
    return this.http.post(`${this.apiUrl}/auth/email-check`, payload);
  }

  // isTeamAdmin(): boolean {
  //   return this.q9UserService.user ? this.q9UserService.user.userInTeam.teamAdmin : false;
  // }

  checkAccessToEnterprise(): boolean {
    const enterprisePermissions = get(this.q9UserService.user, 'enterprisePermissions', {});
    const {enterpriseExists = false, hasAccess = false} = enterprisePermissions;

    return enterpriseExists && hasAccess;
  }

  isPublicSpace(): boolean {
    return this.q9UserService.user && this.q9UserService.user.userInTeam.team.isPublic;
  }

  isTeamSupportCreateDocPackages() {
    const user = this.q9UserService.user;
    const team = user.userInTeam.team;

    return team.supportDocPackages;
  }

  isTrainingsEnabled() {
    return get(this.q9UserService.user, 'userInTeam.team.plan.enabledTrainings');
  }

  getThemes() {
    return get(this.q9UserService.user, 'userInTeam.themes');
  }

  getNumberOfSFLicence() {
    return get(this.q9UserService.user, 'userInTeam.organization.plan.numberOfSfLicense');
  }

  private _setToken(token: string, remember?: boolean) {
    this.cleanCookie();

    if (remember) {
      const date = new Date();
      date.setMonth(date.getMonth() + 1);
      return this.cookieService.set(
        'auth-token',
        token,
        date,
        '/',
        environment.DOMAIN,
        environment.SECURE_COOKIES,
        AuthService.getSameSite()
      );
    }
    return this.cookieService.set(
      'auth-token',
      token,
      null,
      '/',
      environment.DOMAIN,
      environment.SECURE_COOKIES,
      AuthService.getSameSite()
    );
  }

  public getToken(): string {
    return this.cookieService.get('auth-token');
  }

  changeSpace(
    space: TeamInterface,
    {navigationExtras = null, routePath = 'teams'} = {navigationExtras: null, routePath: 'teams'}
  ): Promise<any> {
    const routeSnapshot = this.route.snapshot;
    const teamIndex = +routeSnapshot.firstChild.paramMap.get('teamId');

    if (teamIndex === space.teamIndex) {
      this.teamUtilService.setTeamIndex(space.teamIndex);
      this.teamUtilService.setTeamId(space.team.id);

      return this.fetchUserData(true).then(() => {
        return this._transfer(space, navigationExtras, routePath);
      });
    } else {
      this.teamUtilService.setTeamId(space.team.id);
      this.teamUtilService.setTeamIndex(space.teamIndex);
      return this._transfer(space, navigationExtras, routePath);
    }
  }

  canCreateSpace(): boolean {
    const user = this.q9UserService.user;
    const enterpriseExists = get(user, 'enterprisePermissions.enterpriseExists');
    const isVerified = get(user, 'enterprisePermissions.isVerified');
    const isPrivateCloud = environment.CLOUD_TYPE === 'private';

    return !isPrivateCloud || (enterpriseExists && isVerified);
  }

  fetchUserData(clearCache?: boolean, updateAvatars?: boolean): Promise<any> {
    // if (!this.enterpriseSubject.getValue()) {
    //   this.loadEnterprise();
    // }

    return new Promise((resolve, reject) => {
      if (this.getToken()) {
        const user = this.q9UserService.user;
        if (user && !clearCache) {
          resolve(user);
        } else {
          this.fetchUser(updateAvatars)
            .then(result => resolve(result))
            .catch(err => reject({...err, skipResolver: true}));
        }
      } else {
        reject({status: 'no_token', error: 'Auth error'});
      }
    });
  }

  // getEnterprise() {
  //   return this.enterprise;
  // }

  private getUserState(): string {
    if (!this.getToken()) {
      return 'unauthorized';
    } else if (!this.q9UserService.user) {
      return null;
    } else if (this.q9UserService.user.state === 'not_verified' && !this.havePassword()) {
      return 'no_password';
    } else {
      return this.q9UserService.user.state;
    }
  }

  private havePassword(): boolean {
    const user = this.q9UserService.user as any;
    if (typeof user.havePassword === 'undefined') {
      return true;
    } else {
      return user.havePassword;
    }
  }

  private setupRaven(user) {
    const userInTeam = typeof user.userInTeam.team === 'string' ? null : user.userInTeam.team;

    Raven.setUserContext({
      id: user.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      createdAt: user.createdAt,
      userInTeamId: (userInTeam && userInTeam.id) || '',
      userInTeamName: (userInTeam && userInTeam.name) || '',
      planType: (userInTeam && userInTeam.plan.type) || ''
    });
    Raven.setEnvironment(environment.ENV);
  }

  private _storeSession(user) {
    this.q9UserService.user = user;
    this.setupRaven(user);
    return new Promise((resolve, reject) => {
      this.firebaseService
        .authenticate(user.firebaseToken)
        .then(() => resolve(user))
        .catch(err => reject(err));
    });
  }

  private _transfer(space: TeamInterface, navigationExtras, routePath): any {
    const commands = ['t', space.teamIndex];

    if (routePath) {
      commands.push(routePath);
    }

    return this.router
      .navigate(commands, navigationExtras || {queryParamsHandling: 'preserve'})
      .then(() => Promise.resolve());
  }

  private fetchUser(updateAvatars?: boolean | undefined) {
    let user;
    return Promise.resolve()
      .then(() => this.http.get(`${this.apiUrl}/account`).toPromise())
      .then((userFromServer: any) => {
        user = userFromServer;

        if (user.state === 'active') {
          return this._fetchUserEnterprisePermissions();
        }

        return Promise.resolve();
      })
      .then(enterprise => {
        if (enterprise) {
          user = Object.assign(user, {enterprisePermissions: enterprise});
        }

        if (updateAvatars) {
          // update avatars urls for browsers reload it instead of cache
          // check is avatar exists to prevent show incorrect images
          user.avatar_sm = user.avatar_sm ? user.avatar_sm + '?time=' + Date.now() : user.avatar_sm;
          user.avatar_md = user.avatar_md ? user.avatar_md + '?time=' + Date.now() : user.avatar_md;
          user.avatar_lg = user.avatar_lg
            ? +user.avatar_lg + '?time=' + Date.now()
            : user.avatar_lg;
        }
        // if don't need to update avatar simply set response user to scope

        return Promise.resolve(this._storeSession(user));
      })
      .catch(err => Promise.reject(err));
  }

  private _fetchUserEnterprisePermissions(): any {
    return this.http.get(`${this.apiUrl}/account/enterprise/permissions`).toPromise();
  }

  private _clearSession() {
    this.q9NotificationsService.signOut();
    this.q9TaskNotificationsService.signOut();
    this.firebaseService.unauth();
  }

  signinWithToken(token: string) {
    this._setToken(token);
    return this.http
      .get(`${this.apiUrl}/account`)
      .toPromise()
      .then(user => {
        this._storeSession(user);
        return user;
      });
  }

  public cleanCookie(): void {
    return this.cookieService.set(
      'auth-token',
      '',
      new Date(null),
      '/',
      environment.DOMAIN,
      environment.SECURE_COOKIES,
      AuthService.getSameSite()
    );
  }

  private getRedirect(): string | void {
    if (this.cookieService.check('redirect')) {
      const redirectUrl = this.cookieService.get('redirect');

      return decodeURIComponent(redirectUrl);
    }
  }

  setRedirect(url: string): void {
    this.cookieService.set(
      'redirect',
      encodeURIComponent(url),
      null,
      '/',
      environment.DOMAIN,
      environment.SECURE_COOKIES,
      AuthService.getSameSite()
    );
  }

  private deleteRedirect(): void {
    this.cookieService.delete(
      'redirect',
      '/',
      environment.DOMAIN,
      environment.SECURE_COOKIES,
      AuthService.getSameSite()
    );
  }

  private destroyIntercom() {
    this.q9IntercomService.fireEvent('DESTROY');
  }

  public async checkState(states = [], clearCache = false) {
    const userState = this.getUserState();
    if (userState === null || clearCache) {
      const user = await this.fetchUserData(clearCache).catch(err => {
        throw {fetchUserData: true, err};
      });
      const currentState = this.getUserState();

      if (states.indexOf(currentState) >= 0) {
        return user;
      } else {
        throw clearCache ? currentState : {state: currentState};
      }
    } else if (states.indexOf(userState) >= 0) {
      return this.q9UserService.user;
    } else {
      throw userState;
    }
  }

  loadEnterprise(params: {itemType: 'space' | 'user'}) {
    return this.http
      .get(`${this.apiUrl}/account/enterprise/admins`, {params})
      .pipe(catchError(this.handleError));
  }

  isPersonalSpace() {
    if (!this.q9UserService.user) {
      return this.fetchUserData().then(user => {
        if (!user.userInTeam.team.personal) {
          return Promise.resolve();
        } else {
          return Promise.reject({state: this.getUserState()});
        }
      });
    } else if (!this.q9UserService.user.userInTeam.team.personal) {
      return Promise.resolve();
    } else {
      return Promise.reject({state: this.getUserState()});
    }
  }

  redirectIfPersonalSpace() {
    return Promise.resolve().then(() => {
      this.fetchUserData().then(user => {
        if (user && user.userInTeam && user.userInTeam.team && !user.userInTeam.team.personal) {
          return Promise.resolve();
        } else {
          const teamIndex = user.userInTeam.teamIndex;
          this.router.navigate(['t', teamIndex, 'teams']);
          return Promise.resolve();
        }
      });
    });
  }

  isPrivateCloudType() {
    const currentCloudType = environment.CLOUD_TYPE || CloudType.PUBLIC;

    return currentCloudType === CloudType.PRIVATE;
  }

  canImportMap() {
    return [CloudName.US, CloudName.CISCO].includes(environment.CLOUD_NAME as any);
  }

  uploadAvatar(param: {key: string}): Promise<any> {
    return this.http.post(`${this.apiUrl}/avatar`, param).toPromise();
  }

  removeAccount(param: {password: string}): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/account`, param, {responseType: 'text'})
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }

  getNotificationByType(
    notificationType: string,
    notificationMessage?: string
  ): {text: string; isError: boolean} {
    switch (notificationType) {
      case 'validation_failed':
        return {text: notificationMessage, isError: true};
      case 'authentication_failed':
        return {text: notificationMessage || 'GENERAL.ERROR', isError: true};
      case 'server_down':
        return {text: 'GENERAL.SERVER_DOWN', isError: true};
      case 'token_expired':
        return {text: 'SIGNIN.LINK_EXPIRED', isError: true};
      case 'invalid_param':
        return {text: 'SIGNIN.INVALID_PARAM', isError: true};
      case 'email_pwd_not_valid':
        return {text: 'SIGNIN.EMPTY_PASS_EMAIL', isError: true};
      case 'email_verified':
        return {text: 'SIGNIN.INVITE_ACCEPTED', isError: false};
      case 'invite_accept':
        return {text: 'SIGNIN.VERIFY_ACCEPTED', isError: false};
      case 'sf_not_allowed':
        return {text: 'SIGNIN.ALLOWED_FOR_EXISTING_USERS', isError: true};
      case 'user_not_found':
        return {text: notificationMessage || 'SIGNIN.USER_NOT_FOUND', isError: true};
      default:
        return null;
    }
  }
}
